<template>
  <div class="total_box">
    <h4>{{$t("sabre.booking.order-detail-box.total")}}</h4>
    <div class="total_box_list">
      <div class="d-flex justify-content-between">
        <h5>{{$t("sabre.booking.order-detail-box.flight-price")}}</h5>
        <span>{{`$${pricePerPerson}`}}</span>
      </div>
      <div class="d-flex justify-content-between">
        <div class="total_pbox">
          <h5>{{$t("sabre.booking.order-detail-box.handling-fee")}}</h5>
          <small>{{$t("sabre.booking.order-detail-box.price-notice")}}</small>
        </div>
        <span>2,220 ₪</span>
      </div>
    </div>
    <div class="total_box_footer">
      <div class="d-flex justify-content-between">
        <h5>{{$t("sabre.booking.order-detail-box.flight-price")}}</h5>
        <span>{{`$${Math.round(totalPrice)}`}}</span>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  name: 'order-detail-area-price-box',
  computed: {
    ...mapGetters({
      condition: 'GET_SABRE_ORDERED_CONDITION',
      priceInfo: 'GET_FO_SABRE_BOOKING_PRICE_INFO',
    }),
    adults() {
      const { condition } = this;
      return +condition.passengerComposition.adults || 0;
    },
    children() {
      const { condition } = this;
      return +condition.passengerComposition.children || 0;
    },
    babies() {
      const { condition } = this;
      return +condition.passengerComposition.babies || 0;
    },
    seniors() {
      const { condition } = this;
      return +condition.passengerComposition.seniors || 0;
    },
    persons() {
      const { adults, children, babies, seniors } = this;
      return adults + children + babies + seniors;
    },
    totalPrice() {
      const { priceInfo } = this;
      return priceInfo.totalPrice;
    },
    pricePerPerson() {
      const { totalPrice, persons } = this;
      return Math.round(totalPrice / persons);
    },
  },

};
</script>
